.layout-navbar {
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
  box-shadow: 0px 10px 68px 0px rgba(0, 0, 0, 0.08);
}

.navbar-brand a {
  text-decoration: none;
}

.nav-link-news,
.nav-link-information,
.nav-link-faq,
.nav-link-contact {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 500;
  font-size: 19px !important;
  line-height: 29px;
  text-align: center;
  color: #494949;
  padding-left: 30px !important;
}

.nav-link-news:hover,
.nav-link-information:hover,
.nav-link-faq:hover,
.nav-link-contact:hover {
  color: navy !important;
  text-decoration: underline;
}

.layout-navv-icon-thai {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 43px;
  letter-spacing: -0.015em;
  color: navy;
  padding-left: 20px;
  /* display:block; */
}

.layout-navv-icon-lang {
  display: flex;
  width: 42px;
  height: 42px;
  padding: 7px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0.8px solid rgba(0, 0, 0, 0.30);
  color: #000;
  font-size: 18px;
  font-family: Inter;
  font-weight: 700;
}

.layout-navv-icon-english {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 34px;
  color: navy;
  /* display:block; */
}

.active {
  display: block;
  color: var(--blue-2, #060290) !important;

}

.language-switcher .nav-link-language-switcher {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.nav-link-mobile {
  display: none;
}

@media (max-width: 768px) {
  .navbar-toggler-icon {
    background-image: url("../../assets/images/hamburger.png");
    width: 70px;
    height: 66px;
    z-index: 11;
  }

  .navbar-toggler {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    z-index: 20;
    overflow-y: hidden;
  }

  .nav-items-mobile {
    position: relative;
    top: 25%;
  }

  .nav-link-news:hover,
  .nav-link-information:hover,
  .nav-link-faq:hover,
  .nav-link-contact:hover {
    color: navy !important;
    text-decoration: underline;
  }

  /* .navbar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 100vh;
    overflow-y: hidden;
    background: white;
  } */

  .layout-navv-icon-english {
    display: none;
  }

  .layout-navv-icon-thai {
    display: none;
  }

  .nav-link-mobile {
    display: block;
  }

  .nav-link-news,
  .nav-link-information,
  .nav-link-faq,
  .nav-link-contact {

    font-size: 16px !important;
    padding-left: 10px !important;
  }

}

.show-logo {
  display: block;
}

.show-logo-mobile {
  display: none;
}

@media screen and (max-width:959px) {
  .show-logo {
    display: none;
  }

  .show-logo-mobile {
    display: block;
  }
}

.navbar-expand-md .navbar-collapse {
  justify-content: center;
}

.dropdown-toggle {
  font-size: 19px !important;
}