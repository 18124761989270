.contactUs {
  /* margin-top: 90px;  */
  /* adjust this value based on the height of your navbar */
  background: var(--bg-blue, linear-gradient(222deg, #151015 0%, #060290 100%));
}

.contactt-box-right {
  background: radial-gradient(91.54% 86.63% at 4.61% 1.38%,
      #ffffff 0%,
      #fcfcfc 100%) !important;
  height: 671px !important;
  position: relative;
  width: calc(100% - 72px);
  left: 72px;
}

.contactt-box {
  display: flex !important;
}

.contactt-box-left {
  background: linear-gradient(221.95deg,
      #151015 16.97%,
      #060290 83.68%);
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  width: 72px !important;
  height: 671px;
  position: absolute;
  left: 0;
}

.contact-map {
  background: #ffffff;
  box-shadow: -17px 30px 64px rgba(0, 0, 0, 0.12);
  height: 440px;
}

.phoneIcon {
  background-color: grey;
}

.contact-map-img {
  height: 440px;
  width: 100%;
}

.row-title {
  height: 90px;
}

.row-title h2 {
  color: navy;
  font-weight: 900;
  font-size: 35px;
  padding-top: 60px !important;
  margin-left: 20px !important;
}

.row-title-img {
  margin-top: 40px;
  right: 10;
  position: absolute;
  height: 45px;
  width: 80px;
}

.map {
  position: relative;
  top: 25%;
}

.map button {
  margin: 10px;
}

.map-title {
  background-color: #f1f1f1;
  color: navy;
}

.row-pages {
  height: 70px;
}

.row-pages a {
  color: navy;
  font-weight: 600;
  margin: 5px 20px;
  text-decoration: none;
}

.rowIcon {
  height: 400px;
  align-items: center;
  justify-self: center;
}

.rowwIcon {
  background-image: url(../../assets/images/logo-i.png);
  /*-webkit-background-size :cover ;
    -moz-background-size:cover;
    -moz-background-size: cover;*/
  height: 200px;
  width: 700px !important;
  margin: auto !important;
  /*margin-top:40px !important;
   margin-bottom: 40px !important;*/
  /*margin:50px 400px ;
   margin-top:100px !important;

    background-position: bottom right !important;
    background-repeat: no-repeat;*/
}

.rowwwIcon {
  text-align: center;
  margin: 70px;
  align-items: center;
}

.rowwwIcon i {
  margin: 0 10px;
  margin-top: 10px;
}

.rowwIcon span {
  color: white;
}

.contact-subheadings a.contact-subhead:hover {
  color: navy !important;
}

.contact-subhead {
  margin: 6px !important;
  font-weight: 500 !important;
  margin: 20px !important;
  text-decoration: none !important;
}

@media screen and (max-width: 767px) {
  .sidebar {
    display: none;
  }

  .content {
    width: 100%;
    margin-left: 0px !important;
    height: 920px !important;
  }

  .contact-logo {
    display: none;
  }

  .contact-title-row {
    height: 90px !important;
    border-bottom: 0px !important;
  }

  .contact-subheadings {
    height: 50px !important;
    margin-top: 10px !important;
    margin-left: 1px !important;
  }

  .contact-subhead {
    margin: 6px !important;

  }

  .contact-map-col {
    height: 330px !important;
  }

  .contact-map-title {
    font-size: 20px !important;
    margin-bottom: 16px;
  }

  .iconMap {
    height: 24px;
  }

  .contact-map-subtitle {
    font-size: 12px !important;
  }

  .contact-map-btn {
    border-radius: 8px !important;
    font-size: 14px !important;
    margin-left: 0px !important;
    padding: 10px 12px !important;
  }

  .contact-map-btn-pos {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .contact-map-col2 {
    height: 440px !important;
  }

  .idmb-icon-res {
    height: 230px !important;
  }

  .contact-row-new-idmb-res {
    height: 145px !important;
    width: 330px !important;
  }

  .row-new-idmb-title {
    margin-top: 30px !important;
  }

  .iconImg {
    width: 32px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sidebar {
    width: 30px !important;
  }

  .content {
    margin-left: 30px !important;
    height: 700px !important;
  }
}