.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  height: 108px;
  /* border-top: 12px solid #060290; */
  margin-top: 3px;
}

.footer a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  color: grey;
  font-size: 12px;

}

/* @font-face {
  font-family: "Mosse";
  src: url("../../../public/fonts/mosse-thai-bold.otf") format("opentype");
} */

.iconFooter {
  height: 30px;
  margin-right: 10px;
}

.navv-icon-thai {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 43px;
  letter-spacing: -0.015em;
  background: linear-gradient(175.03deg, #0232a1 4%, #002885 114.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.navv-icon-english {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 34px;
  background: linear-gradient(175.03deg, #0232a1 4%, #002885 114.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.footer-mapLink {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 114.1%;
  color: #575757;
  max-width: 400px;
  margin-right: 23px;
}

.footer-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Mosse";
  margin-left: 33px;
}

.footer-bottom {
  border-top: 0.8px solid #000000ba;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 33px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .iconFooter {
    height: 26px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    height: 150px;
    border-top: 0px;
    margin-top: 0px;
  }

  .footer-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }

  .footer-mapLink {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
  }

  .iconFooter {
    height: 26px;
    margin-left: 5px;
  }

  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58px;
  }

  .footer-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}