.page-container {
  display: flex;
  flex-direction: column;
  margin-top: 54px;
}

/* new styles start */
.page-body {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.mainView-container {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.sideView-container {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.mainView-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  max-height: 300px;
  padding-left: 70px;
  border-left: 72px solid #060290;
  background: linear-gradient(149.35deg, #ffffff 42.02%, #fcfcfc 97.25%);
  border-bottom: 1px solid #00000024;
  border-right: 1px solid #00000024;
}

.mainView-header-title {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 116.6%;
  letter-spacing: 0.005em;
  color: #9b9b9b;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mainView-header-subTitle {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 126%;
  letter-spacing: -0.015em;
  color: #060290;
  flex: none;
  order: 0;
  flex-grow: 0;
  max-width: 60%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mainView-header-date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding-top: 5px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.93);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.mainView-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #00000024;
  /* margin-bottom: 50px; */
  margin-bottom: -82px;
}

.sideView-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 165px;
  /* border-bottom: 1px solid #00000024; */
  padding-bottom: 20px;
  padding-top: 20px;
  margin-left: 30px;
}

.sideView-header-title {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 116.6%;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #0d1d2f;
}

.sideView-header-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 101px;
  height: 47px;
  background: #060290;
  border-radius: 25px;
  margin-top: 10px;
  border: none;
}

.sideView-body {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.sideView-body-title {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.56);
  padding: 40px 0px 25px 0px;
}

.sideView-news-body {
  display: flex;
  min-height: 120px;
  margin: 0px 0px 20px 0px;
  cursor: pointer;
}

.sideView-news-title {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.166;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0d1d2f;
  /* max-width: 80%; */
}

.sideView-news-link {
  display: flex;
  flex-direction: column;
  padding-right: 56px;
}

.sideView-news-img {
  width: 120px;
  height: 120px;
}

.sideView-news-date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #332929;
}

.sideView-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 0px 20px;
}

/* new styles end */

.news {
  margin-top: 90px;
}

/* .news {
  margin-bottom: 60px;
} */

.rowTitle {
  height: 180px;
  background: rgb(2, 0, 36);
  background: linear-gradient(355deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(21, 21, 107, 1) 34%,
      rgba(3, 0, 255, 0.9949229691876751) 100%);

}

.title {
  color: var(--blue-2, #060290);
  font-size: 58px;
  font-family: Mosse;
  font-style: normal;
  font-weight: 700;
  line-height: 116.6%;
  letter-spacing: 0.29px;
  margin-left: 116px;

  padding-top: 100px;
  padding-bottom: 25px;
}

.joinMov {
  text-align: left !important;
  padding-left: 40px;
  padding-top: 40px;
  margin-bottom: 20px;
  color: var(--text, #0D1D2F);
  font-size: 24px;
  font-family: Mosse;
  font-style: normal;
  font-weight: 700;
  line-height: 116.6%;
  letter-spacing: -0.36px;
}

.joinButton {
  background: #060290 !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  text-align: left !important;
  font-family: Mosse;
  margin-left: 40px !important;
}

.col-md-4 {
  background-color: white;
}

.rti--container {
  padding-left: 2.375rem !important;
  width: 600px;
  margin-top: 40px;
  margin-left: 80px;
  margin-bottom: 40px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #012D91;
  margin-left: 80px;
}

.column {
  float: left;
  width: 100%;
  padding: 0px 80px;
}

.newsHeadings {
  margin: 0px 30px;
  color: #000000;
  font-family: Mosse;
  font-size: 17px !important;
  font-weight: 200 !important;
}

.newsLink {
  text-decoration: none;
}

.newsLink :hover {
  color: #002885 !important;
  text-decoration: none !important;
  bottom: -4px;
  left: 20px;
}

a :hover {
  text-decoration: none !important;
}

.newsLink :hover ::after {
  content: "";
  width: 30%;
  height: 2px;
  background-color: navy;
  position: absolute;
  bottom: -4px;
  left: 20px;
}

#newsDate {
  color: rgba(0, 0, 0, 0.32);
  font-weight: 0;
  font-size: 12px;
  padding-bottom: 10px;
  text-align: left !important;
  font-family: Mosse;
  font-style: normal;
}

.cardd h2 {
  color: #060290;
  font-family: Mosse;
  font-weight: 650;
  font-size: 23px;
  padding: none !important;
  text-decoration: none !important;
}

.cardd p {
  padding-bottom: 20px;
}

/* @font-face {
  font-family: "Mosse";
  src: url("../../../public/fonts/mosse-thai-bold.otf") format("opentype");
} */

.cardd-news button {
  padding: 5px 15px;
  margin: 0px 10px;
  margin-bottom: 20px;
  background: #ff8a00;
  border-radius: 8px;
  color: #ffffff;
  font-family: Mosse;
  font-weight: 100;
  font-size: 13px;
}

.cardd img {
  margin-top: 30px;
  object-fit: cover;

}



.cardd-latest h6 {
  color: #332929;
  font-size: 15px !important;
  font-family: Mosse;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 150%;
}

.cardd-latest h3 {
  color: #0d1d2f;
  font-weight: 700;
  font-size: 17px !important;
  letter-spacing: -0.015em !important;
  font-family: Mosse;
}

.latest-img {
  margin-bottom: 30px;
}

.latest-news h2 {
  color: rgba(0, 0, 0, 0.56);
  font-size: 21px;
  font-family: Mosse;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.21px;
}

.roww {
  text-align: center !important;
  align-items: center !important;
}

.iconImgNews {
  width: 36px;
  color: #ffffff;
  margin: 10px;
}

.newsArticleImg {
  height: 480px;
  width: 850px;
  object-fit: cover;
  margin-top: 120px;
  margin-bottom: 120px;
}

#newsArticleDate {
  color: grey;
  font-weight: 200;
  font-size: small;
  padding-bottom: 10px;
  text-align: left !important;
  margin-left: 40px;
}

.newsArticle {
  margin: 40px;
}

.newsArticlePara {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #332929;
  padding: 1% 10%;
}

.cardd-news {
  margin-left: 40px;
}

.cardd-news h6 {
  color: grey;
  font-weight: 200;
  font-size: small;
  padding-bottom: 10px;
  /*text-align: right !important;  */
}

.cardd-news h2 {
  color: navy;
  font-weight: 650;
  font-size: 23px;
  text-align: left !important;
  /*padding:0 40px;*/
}

.cardd-news p {

  text-align: left !important;
  /*padding-left: 40px !important;*/
  padding-right: 20px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.cardd-btn {
  position: absolute;
  align-items: baseline;
  float: left;
}

.card-content-mobile {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--text, #0D1D2F);
  font-size: 18px;
  font-family: Mosse;
  line-height: 160%;
  letter-spacing: -0.09px;
  margin-bottom: 15px;
}

.latest-news {
  padding: 0px 33px;
}

.cardd-latest img {
  margin-top: 0px !important;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .sideView-container {
    display: none;
  }

  .mainView-container {
    width: 100%;
  }

  .mainView-header {
    border-left: 0px solid #060290;
    background: linear-gradient(149.35deg, #ffffff 42.02%, #fcfcfc 97.25%);
    border-bottom: 1px solid #00000024;
    border-right: 1px solid #00000024;
    padding-left: 20px;
  }

  .news-title-mobile {
    display: none !important;
  }

  .rti--container {
    padding-left: 2.375rem;
    width: 300px;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .has-search .form-control-feedback {
    margin-left: 10px;
  }

  .column {
    padding: 0px 15px;
  }

  .newsHeadings {
    margin: 0px 7px 0px 7px !important;
    font-size: 13px !important;
    font-weight: 200 !important;
  }

  .cardd-news {
    margin-left: 10px;
  }

  .card-content-mobile {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--text, #0D1D2F);
    font-size: 18px;
    font-family: Mosse;
    line-height: 160%;
    letter-spacing: -0.09px;
  }

  .card-content-mobile::after {
    content: "...";
    display: inline;
  }

  .cardd-news h2 {
    font-size: 18px;
  }

  .cardd-news p {
    font-size: 15px;
  }

  .cardd-news-img {
    width: 105px !important;
    height: 105px !important;
    margin-top: 20px !important;
  }

  .latest-news-mobile {
    display: none;
  }

  .news-content-mobile {
    border-right: 0px !important;
    /* padding-right: 0px !important; */
    border-top: 0px !important;
  }

  .title {
    margin-left: 10px;
    padding: 40px;
    font-weight: 700;
    font-size: 24px;
    height: 150px;
    font-family: "Mosse";

  }

  .news-article-heading {
    border-right: 0px !important;
    padding-right: 0px !important;
  }

  #newsArticleDate {
    color: rgba(0, 0, 0, 0.93);
    font-size: 13px;
  }

  .newsArticle {
    margin: 15px;
  }

  .newsArticleImg {
    height: 220px;
    width: 390px;
    object-fit: cover;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mainView-header-subTitle {
    font-family: "Mosse";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 116.6%;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #060290;
  }

  .newsArticlePara {

    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #332929;
  }

  .latest-news {
    padding: 0px 20px !important;
  }

  .latest-news-title-mobile {
    display: none;
  }
}

.has-search {
  margin-top: 40px;
  margin-left: 75px;
}

@media screen and (min-width: 768px) and (max-width: 1250px) {
  .rti--container {
    padding-left: 2.375rem;
    width: 450px;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .newsArticleImg {
    height: 350px;
    width: 550px;
  }

  .has-search .form-control-feedback {
    margin-left: 20px;
  }

  .column {
    padding: 0px 0px;
  }

  .newsHeadings {
    margin: 0px 9px 0px 9px !important;
    font-size: 17px !important;
    font-weight: 200 !important;
  }

  .cardd-news {
    margin: 0 20px !important;
  }

  .cardd-news h2 {
    font-size: 22px;
  }

  .cardd-news p {
    font-size: 16px;
    padding-bottom: 6px;
  }

  .cardd-news-img {
    width: 128px !important;
    height: 128px !important;
    margin-top: 50px !important;
  }


  .cardd-latest h6 {
    font-size: 18px;
    padding-top: 7px;

  }

  .cardd-latest h3 {
    font-size: 16px !important;
    letter-spacing: -0 !important;
    font-family: Mosse;
  }

  .cardd-latest-img {
    width: 100px !important;
    height: 100px !important;
  }

  .cardd-latest img {
    margin-top: 4px !important;
  }


  .sideView-header-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.56);

  }

  .sideView-header {
    min-height: 200px;
    max-height: 300px;
  }

  .mainView-container {
    width: 66%;
  }

  .sideView-container {
    width: 34%;
  }

  .sideView-news-body {
    display: flex;
    flex-direction: column;
  }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important;
  border-bottom: 5px solid #002885 !important;
}

.news-articles {
  margin-left: 80px;
}

.nav-link {
  color: #000 !important;
  font-size: 17px;
  font-family: Mosse;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.085px;
  border: none !important;
}

.news-title {
  color: #060290;
  font-size: 28px;
  font-family: Mosse;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.28px;
}

.tag {
  margin-right: 10px;
  height: 32px;
  padding: 6px 14px 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 28px;
  background: #F2F2F2;
  color: #242424;
  font-family: Mosse;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.6%;
  letter-spacing: -0.15px;
}

.tag:hover {
  margin-right: 10px;
  height: 32px;
  padding: 6px 14px 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 28px;
  background: #F2F2F2;
  color: #242424;
  font-family: Mosse;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.6%;
  letter-spacing: -0.15px;
}


.cardd-btn {
  position: absolute;
  align-items: baseline;
  float: left;
}

.date-news {
  color: rgba(0, 0, 0, 0.56);
  font-size: 20px;
  font-family: Inter;
  line-height: 150%;
  padding: 11px 20px;
}

.btn-title {
  background: rgb(242, 242, 242);
  padding: 10px;
  color: rgba(0, 0, 0, 0.71);
  font-size: 20px;
  font-family: Inter;
  line-height: 150%;
  margin-right: 20px;
}



.read-more {
  width: 169px;
  height: 56px;
  padding: 8px 18px 14px 18px;
  border-radius: 38px;
  border: 1px solid #242B3D;
  background: #FFF;
  color: #30384E;
  font-family: Mosse;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.255px;
}

.read-more:hover {
  width: 169px;
  height: 56px;
  padding: 8px 18px 14px 18px;
  border-radius: 38px;
  border: 1px solid #242B3D;
  background: #FFF;
  color: #30384E;
  font-family: Mosse;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.255px;
}

.box-read-more {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 80px;
}

.box-h {
  position: absolute;
  top: 0;
  left: 0;
  width: 78px;
  height: 100%;
  background: linear-gradient(355deg, rgba(2, 0, 36, 1) 0%, rgba(21, 21, 107, 1) 34%, rgba(3, 0, 255, 0.9949229691876751) 100%);
}

@media screen and (min-width:960px) and (max-width: 1280px) {
  .box-h {
    width: 16px;
  }

  .title {
    margin-left: 41px;
    font-size: 32px;
  }

  .joinMov {
    color: rgba(0, 0, 0, 0.56);
    font-size: 17px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.17px;
  }

  .news-articles {
    margin-left: 25px;
  }

  .has-search {

    margin-left: 20px;
  }
}

.latest-news-title {
  color: var(--text, #0D1D2F);
  font-size: 20px;
  font-family: Mosse;
  font-style: normal;
  font-weight: 700;
  line-height: 116.6%;
  letter-spacing: -0.36px;
}

.latestNewsTitle {
  margin-bottom: 28px;
}


@media screen and (max-width:959px) {
  .news-articles {
    margin-left: 15px;
    margin-right: 15px;
  }

  .has-search {
    margin-left: 15px;
    margin-right: 12px;
  }

  .news-title {
    color: var(--text, #282828);
    font-family: Mosse;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.18px;
  }

  .card-content-mobile {
    display: none;
  }

  .date-news {
    color: #808080;

    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.255px;
    padding: 0;
  }

}

.latest-container {
  padding: 1% 10%;
  border-right: 1px solid #00000024;
  margin-top: 81px;
}

.rowElements {
  overflow: auto;

}

.rti--input {
  color: #000;
  font-family: Mosse;
  font-size: 19px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.095px;
}

.category {
  color: #797C82;
  font-family: Mosse;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.08px;
}

.rti--tag {
  border-radius: 8px;
  background: #453E36;
  color: #FFF;
  font-family: Mosse;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.6%;
  letter-spacing: -0.18px;
  height: 36px;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
}

.custom-input::placeholder {
  color: #000;
  font-family: Mosse;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.095px;
}

.custom-input {
  height: 50px;
  border-left: none;

}

.text-content {
  color: rgba(35, 37, 40, 0.95);
  font-family: Mosse;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}

.custom-input-text {
  background-color: #fff;
  border-right: none;
  color: #012D91;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu div {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 25px 25px 25px 0px;
  text-decoration: none;
  color: #787878;
  font-family: Mosse;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.16px;
  cursor: pointer;
}

.active-menu {
  border-bottom: 5px solid #2B2B2B;
  color: #2B2B2B !important;
}