.page-container {
    display: flex;
    flex-direction: column;
}

.row-quiz {
    background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 131.19%) !important;
    height: 340px;
    margin-top: 100px;
    text-align: center;

}

.quiz-title {
    color: var(--blue-2, #060290);
    font-size: 60px;
    font-family: Mosse;
    font-weight: 700;
    line-height: 116.6%;
    letter-spacing: -0.6px;
    margin-bottom: 41px;
    padding-top: 100px;
}

.quiz-correct {
    color: #69B868;
    text-align: center;
    font-size: 200px;
    font-family: Mosse;
    font-weight: 700;
    line-height: 116.6%;
    letter-spacing: -2px;

}

.quiz-correct-title {
    color: var(--text, #0D1D2F);
    text-align: center;
    font-size: 48px;
    font-family: Mosse;
    font-weight: 700;
    line-height: 116.6%;
    letter-spacing: -0.48px;
    margin-top: 43px;

}

.quiz-correct-sub {

    color: #69B868;
    text-align: center;
    font-size: 32px;
    font-family: Mosse;
    line-height: 116.6%;

}

.quizz {

    text-align: center;
    background-image: url("../../assets/images/bh2.png");
    height: 400px !important;
    background-size: contain;
    background-repeat: no-repeat;
}

.bg {
    background-image: url("../../assets/images/bgQuiz.png");
    background-size: cover;
}

.quizz-final {

    text-align: center;
    /* background-image: url("../../assets/images/bgQuiz.png"); */
    height: 400px !important;
    background-size: contain;
    background-repeat: no-repeat;
}

.subheading {
    color: rgba(43, 48, 53, 0.50);
    text-align: center;
    text-shadow: 0px 0px 33px 0px #FFF;
    font-size: 28px;
    font-family: Mosse;
    line-height: 130%;
    letter-spacing: -0.28px;
    margin-top: 70px;
    margin-bottom: 28px;
}

.cardd {
    margin-left: 40px;
}


.joinQuizButton {
    background: #060290 !important;
    border-radius: 25px !important;
    color: #FFFFFF !important;
    text-align: left !important;
    font-family: Mosse;

}

.quizNewsButton {
    background-color: #F2F2F2 !important;
    border-radius: 7px !important;
}

.joinQuizMov {
    text-align: left;
    align-items: left;
}

.cardd h6 {
    color: grey;
    font-weight: 200;
    font-size: small;
    padding-bottom: 10px;
    /*text-align: right !important;  */
}

.cardd h2 {
    color: navy;
    font-weight: 650;
    font-size: 23px;
    text-align: left !important;
    /*padding:0 40px;*/
}

.cardd p {
    padding-bottom: 10px;
    text-align: left !important;
    /*padding-left: 40px !important;*/
    padding-right: 20px !important;
}

.cardd-btn {

    position: absolute;
    align-items: baseline;
    float: left;


}

.date-news {
    font-family: Mosse;
    font-weight: lighter;
    font-style: normal;
}

.check-btn {
    background: #FF8A00 !important;


}

.cardd img {
    margin-top: 10px;
}

.cardd-latest h6 {
    color: black;
    font-weight: 150;
    font-size: small;
    padding-top: 10px;
    text-align: left;
}

.cardd-latest h3 {
    color: black;
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    margin-top: 30px;
}

.cardd-latest img {
    margin-top: 30px;
}

.latest-title {
    color: grey;
    font-size: 20px;
    text-align: left;
}

.roww {
    margin-top: 20px;
    text-align: center;
}

.btn-read-more {
    margin-bottom: 100px !important;
}

/* .row-quiz-question {

    height: 600px;

} */

.row-quiz-question p {
    text-align: left;
    margin: 50px 50px;

}

.row-quiz-question img {
    height: 300px;
    width: 550px;
}

.question-card {
    margin: 55px 60px !important;
    margin-right: 80px !important;
    border-radius: 20px !important;
    box-shadow: 0 2px 4px rgba(128, 128, 128, 0.5);
}

.card {
    border-color: transparent;
    /* Set the border color to transparent */
}

.question-card-h4 {
    text-align: left;
    padding: 10px;
    padding-left: 21px;
    font-family: 'Mosse';
    font-style: normal;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: rgba(43, 48, 53, 0.5);

}

.question-card-h2 {
    font-size: 30px;
    text-align: left;
    color: navy;
    padding: 10px 10px;
    font-weight: 700;

}

.question-content {
    margin: 40px !important;
}


.row-quiz-question-img-res {
    /* width: 550px !important;
    height: 300px !important; */
    width: 100% !important;
    object-fit: contain;

}

.row-quiz-question-img-res-q5 {
    width: 550px !important;
    height: 450px !important;

}

.question-card-q3 {
    margin: 80px 50px !important;



}

.question-card-q3 button {

    float: left;
    margin-left: 40px;

}

.question-card-q3 h4 {
    font-size: 20px;
    text-align: left;
    padding: 10px;
    padding-left: 30px;

}

.question-card-q3 h2 {
    font-size: 30px;
    text-align: left;
    color: navy;
    padding: 10px 10px;
    font-weight: 700;

}


.custom-modal .modal-content {
    border: none;
    /* Remove the border from modal-content class */
}

.check-font {
    color: var(--blue-2, #060290);
    font-size: 40px;
    font-family: Mosse;
    font-weight: 700;
    line-height: 116.6%;
    letter-spacing: -0.4px;
}

.check-button {
    font-family: Mosse;

}

.check-question {
    font-size: 25px;
    text-align: left;
    color: navy;
    padding: 10px 10px;
    font-weight: 700;
    line-height: 40px;
}

.quiz-choice-image {
    display: none;
}

.quiz-img-pos {

    margin-top: 80px;
    text-align: center;
}

.mobile {
    display: block;
}

@media screen and (max-width: 767px) {
    .mobile {
        display: none;
    }

    .quiz-description {
        padding-left: 15px !important;
        padding-right: 15px;
        margin-top: 70px !important;

    }

    .news-col-res {
        display: none !important;
    }

    .row-quiz {
        height: 200px;
    }

    .quiz-title {
        /* margin-top: 90px; */
        font-size: 26px;
        margin-bottom: 0px;
    }

    .subheading {
        font-size: 16px;
        /* margin-top: 15px; */
        margin-top: 30px;
    }

    .quizz {
        height: 280px !important;
        background-size: cover;
    }

    .quizz-final {
        background-image: none;
        /* height: 550px !important; */
    }

    .quiz-footer-res {
        height: 200px;
    }

    .row-quiz-question-img-res {
        height: 250px !important;
        width: 350px !important;

    }

    .row-quiz-question-img-res-q5 {
        height: 350px !important;
        width: 350px !important;

    }

    /* .row-quiz-question {
        height: 1200px;
    } */

    .quiz-img-pos {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .question-card {
        margin: 75px 0px !important;

    }

    .question-content {
        margin: 10px 0px !important;
    }

    .check-question {
        font-size: 20px;
        line-height: 30px;
    }

    .check-question-q5 {
        font-size: 18px;
    }

    /* .quiz-question-btn {
        display: none !important;
    } */
    .btn-quiz {
        width: 30px !important;
        height: 30px !important;
        font-weight: 400;
        margin-left: 10px;
    }

    .question-card-h4 {
        color: rgba(43, 48, 53, 0.50);
        text-shadow: 0px 0px 33px 0px #FFF;
        font-size: 16px;
        font-family: Mosse;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.16px;
        padding: 0px 10px !important;
    }

    .quiz-choice-image {
        display: flex;
        width: 22px !important;
        height: 22px !important;
        margin-left: 9px !important;
    }

    .custom-modal {

        width: 100% !important;

    }

    .mobile-news {
        display: block;
        margin-top: 190px;
    }

    /* .footer-quiz-res-mb {
        display: none;
    } */

    .quiz-correct {
        font-size: 100px;
    }

    .quiz-correct-sub {
        font-size: 20px;
    }

    .quiz-correct-title {
        font-size: 25px;
    }

}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .mobile {
        display: none;
    }

    .news-col-res {
        display: none !important;
    }

    .row-quiz {
        height: 200px;
    }




    .quizz {
        height: 500px !important;
    }

    .subheading {
        margin-top: 6px !important;
    }

    .quiz-correct {
        font-size: 100px;
    }

    .quiz-correct-sub {
        font-size: 20px;
    }

    .quiz-correct-title {
        font-size: 25px;
    }


}

.btn-quiz {
    /* border-radius: 39px;
    border: 4px solid #052C8F;
    background: #FFF;
    display: flex;
    width: 56px;
    height: 56px;
    padding: 12px 24px 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #052C8F;
    font-size: 22px;
    font-family: Mosse;
    line-height: 116.6%;
    letter-spacing: -0.22px; */
    border-radius: 8px;
    background: #FF8A00;
    display: flex;
    width: 56px;
    height: 56px;
    padding: 12px 24px 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-size: 22px;
    font-family: Mosse;
    line-height: 116.6%;
    letter-spacing: -0.22px;
}

.btn-quiz:hover {
    border-radius: 8px;
    background: #FF8A00;
    display: flex;
    width: 56px;
    height: 56px;
    padding: 12px 24px 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-size: 22px;
    font-family: Mosse;
    line-height: 116.6%;
    letter-spacing: -0.22px;
}

.quiz-description {
    color: rgba(35, 37, 40, 0.95);
    font-size: 20px;
    font-family: "Mosse";
    margin-top: 95px;
    padding-left: 75px;
}

.text-alert {
    margin-top: 15px;
    color: rgba(43, 48, 53, 0.50);
    text-align: center;
    text-shadow: 0px 0px 33px 0px #FFF;
    font-size: 25px;
    font-family: Mosse;
    line-height: 130%;
    letter-spacing: -0.25px;
}

.quiz {
    border: 4px solid #052C8F;
    background: #FFF;
    display: flex;
    width: 56px;
    height: 56px;
    padding: 20px 24px 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #052C8F;
    font-size: 22px;
    font-family: Mosse;
    line-height: 116.6%;
    letter-spacing: -0.22px;
    color: #060290;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 600;
    border-radius: 39px;
}

.btn-next {
    display: flex;
    width: 168px;
    padding: 11px 24px 15px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #FF8A00;
    color: #FFF;
    font-size: 22px;
    font-family: Mosse;
    line-height: 116.6%;
    letter-spacing: -0.22px;
}

.btn-next:hover {
    display: flex;
    width: 168px;
    padding: 11px 24px 15px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #FF8A00;
    color: #FFF;
    font-size: 22px;
    font-family: Mosse;
    line-height: 116.6%;
    letter-spacing: -0.22px;
}

.footer-quiz-res-mb {
    margin-top: -20px;
}

.title-share {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-family: Mosse;
    font-weight: 700;
    line-height: 116.6%;
    letter-spacing: -0.48px;
    text-align: center;
}

.modal-header {
    border: none !important;
}

.m-body {
    color: #000;
    font-size: 24px;
    font-family: Inter;
    font-weight: 500;
    line-height: 116.6%;
    letter-spacing: -0.24px;
    padding: 50px;
    display: flex;
}

@media screen and (min-width: 960px) and (max-width: 1280px) {
    .quiz-img-pos {
        margin-top: 64px !important;
        margin-left: 58px;
    }

    .quiz-description {
        padding-left: 58px !important;
    }

    .question-card {
        margin: 130px 10px 0px 0px !important;
        margin-right: 10px !important;
    }

    .mg-t-200 {
        margin-top: 200px !important;
    }

    .check-question {
        font-size: 24px !important;
    }

    .quiz-correct-title {
        font-size: 32px;
        margin-top: 12px !important;
    }

    .subheading {
        margin-top: 40px !important;
        color: rgba(43, 48, 53, 0.50);
        text-align: center;
        text-shadow: 0px 0px 33px 0px #FFF;
        font-size: 17px;
        font-family: Mosse;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.17px;
    }

    .fon-size-17 {
        font-size: 17px !important;
    }

    .check-font {
        font-size: 35px;
    }

    .quiz-bg {
        background-image: url("../../assets/images/bg-quiz-tablet.png") !important;
        background-size: cover;
        height: 100vh;
    }

    .box-row {
        padding-top: 120px
    }

    .mobile-news {
        display: none;
    }
}



.row-quiz-question {
    height: auto;
}

ิ.box-row {
    height: 600px;
    padding-top: 50px
}

/* .quiz-bg-home {
    margin-top: 42px;
    padding-top: 65px;
} */