.page-container {
  /* display: flex;
  flex-direction: column; */
  overflow: hidden;
}

.slick-prev,
.slick-next {
  width: 12.5px !important;
  height: 26px !important;
}


.contact {
  background: linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%);

  background-size: 100% 100%;
}

.contact-box {
  background-color: #ccdee2;
  height: 780px !important;
  width: 100vw;
  margin-left: 50px !important;
  position: relative;
  margin-bottom: 50px !important;
}

.about-box {
  display: flex !important;
}

.about-box-left {
  background: linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%);
  width: 72px !important;
  height: 1000px;
  position: absolute;
  left: 0;
}

.about-commander-box-right {
  position: relative;
}

.about-commander-box {
  display: flex !important;
}

.about-commander-box-left {
  background: linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%);
  width: 72px !important;
  height: 1300px;
  position: absolute;
  left: 0;
}

.information-navbar {
  height: 160px;
}

.information-title {
  font-family: Mosse;
  margin-left: 40px;
  color: #060290;
  margin-top: 5px;
  line-height: 116.6%;
  letter-spacing: 0.005em;
  font-weight: 700;
}

.info-top-title {
  margin-top: 60px;
}

.about-history-box-right {
  background-color: #ffffff !important;
  height: 4300px !important;
  position: relative;
  width: calc(100% - 72px);
  left: 72px;
}

.about-history-box {
  display: flex !important;
}

.about-history-box-left {
  background: linear-gradient(221.95deg,
      #151015 16.97%,
      #060290 83.68%);
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  width: 72px !important;
  height: 4300px;
  position: absolute;

  left: 0;
}

.about-history-box-right {
  background-color: #ffffff !important;
  height: 4200px !important;
  position: relative;
  width: calc(100% - 72px);
  left: 72px;
}

.about-history-box {
  display: flex !important;
}

.about-history-box-left {
  background: linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%);
  width: 72px !important;
  height: 4200px;
  position: absolute;
  left: 0;
}

.contact-box-about {
  background-color: #f8f8f8;
  height: 1300px !important;
  width: 100vw;
  margin-left: 50px !important;
  position: relative;
  margin-bottom: 50px !important;
}

.logo-img {
  margin-top: 60px;
  margin-right: 0px !important;
  position: absolute;
  float: right !important;
  width: 148px;
  height: 91.619px
}

.row-faq {
  padding-bottom: 80px;
}

.info-heading {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 116.6%;
  margin-left: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  color: #0d1d2f;
}

.info-subheading {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.005em;
  margin-left: 40px;
  margin-bottom: 30px;
  color: #0d1d2f;
  opacity: 0.4;
}

.map {
  position: relative;
  top: 25%;
}

.map button {
  margin: 10px;
}

.map-title {
  background-color: #f1f1f1;
  color: navy;
}

.row-pages {
  height: 90px;
}

.row-pages a {
  color: grey;
  font-weight: 500;
  margin: 5px 20px;
  text-decoration: none;
}

.rowIcon {
  height: 400px;
  align-items: center;
  justify-self: center;
  display: flex;
}

.rowwIcon {
  background-image: url(../../assets/images/logo-i.png);
  height: 200px;
  width: 700px !important;
  margin: auto !important;
}

.rowwwIcon {
  text-align: center;
  margin: 60px;
  align-items: center;
}

.rowwwIcon i {
  margin: 0 10px;
  margin-top: 10px;
}

.rowwIcon span {
  color: white;
}

.faq-questions-info {
  margin: 20px 40px !important;
  padding-right: 20px !important;
  line-height: 150%;
  font-family: "Mosse";
  font-weight: 100;
  font-size: 14px;
  letter-spacing: -0.005em;
  color: #0d1d2f;
}

.faq-answers {
  margin: 0px 55px !important;
}

.info-line {
  margin: 0px 40px !important;
}

.card-about {
  width: 300px;
  height: 300px;
  overflow: hidden;
  box-shadow: none;
}

.card-about .card-body {
  padding: 20px;
}

.card-about .card-title {
  margin-top: 10px;
  font-size: 20px;
}

.rowCardCarousel {
  margin-left: 40px !important;
  margin-right: 40px !important;
  height: auto;
}

.about-card-heading {
  font-family: Mosse;
  color: #0d1d2f;
  font-size: 25px;
  letter-spacing: -0.005em;

}

.about-card-heading2 {
  color: navy;
  margin-top: 70px !important;
}

/* .vlr ::before {
  content: "";
  display: block;
  border-left: 3px solid #060290;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
} */

.vertical {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 0px !important;
  background-color: #060290;
  transform: translateX(-50%) scaleX(0.1);
}

.dott-dot {
  position: absolute;
  left: -9px;
  top: 20%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #060290;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.7), 0 0 9px #808080;
}

.arrow {
  position: absolute;
  bottom: -10px;
  left: -0px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid navy;
}

.roww-history {
  height: 370px;
  position: relative;

}

.history-article-h2 {
  color: navy;
  font-size: 25px;
  font-weight: 700;
  text-align: left;
  margin: 0px 40px;
  font-family: "Mosse";
}

.history-article-h3 {
  font-size: 20px;
  font-weight: 800;
  text-align: left;
  margin: 5px 40px;
  margin-bottom: 10px !important;
  font-family: "Mosse";
}

.history-article-p {
  text-align: left;
  margin-left: 40px !important;
  margin-right: 50px !important;
  margin-bottom: 30px !important;
  font-size: 15px;
  font-family: "Mosse";
  color: var(--text, #0D1D2F);
  font-size: 20px;
  font-family: Mosse;
  line-height: 150%;
  letter-spacing: -0.1px;
  font-weight: 400;

}

.history-article-img {
  padding-right: 50px;
  padding-left: 25px;
}

.history-article-last {
  height: 20px;
}

.his-last {
  margin-bottom: 80px !important;
}

@media screen and (max-width: 767px) {
  .sidebar-information {
    display: none;
  }

  .content-information {
    width: 100%;
    margin-left: 0px !important;
    height: 900px !important;
  }

  .faq-content {
    font-size: 15px !important;
  }

  .row-faq {
    margin-right: 0px !important;
  }

  .information-heading {
    margin-top: 25px !important;
    font-size: 22px !important;
  }

  .information-head {
    font-size: 22px !important;
    margin-top: -4px !important;
  }

  .faq-questions-info {
    font-size: 12px !important;
  }

  .about-history-box-right {
    height: 6200px !important;
    position: relative;
    width: 100%;
    left: 0px;
  }

  .roww-history {
    height: auto;
  }

  .vlr ::before {
    display: none;
  }

  .history-article-img {
    margin-left: 0px !important;
    height: 200px !important;
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 20px !important;
  }

  .about-history-box-right {
    height: 6500px !important;
  }

  .history-mobile {
    flex-direction: column-reverse;
  }

  .history-article-p {
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    font-family: "Mosse",
  }

  .vertical-line {
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2px;
    background-color: #060290;
    margin: auto;
    height: 70px;
    margin-bottom: 5px;
  }

  .dot {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #060290;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.7), 0 0 9px #808080;
  }

  .info-top-title {
    margin-top: 30px;
  }

  .information-navbar {
    height: 120px;
  }
}

.border-right {
  border-right: 3px solid #060290 !important;
}

.dott {

  /* position: absolute; */

  top: 75px;
  transform: translateY(-50%);
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.7), 0 0 9px #808080;
  border-radius: 42px;
  border: 10px solid #EAEAEA;
  background: var(--blue-2, #060290);
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  right: -22px;
  z-index: 1;
}

.dott-left {
  border: solid 1px #00000024;
  width: 100%;
  border-bottom: none;
  margin-left: -35px;
  border-right: 0;
  border-left: 0px;
}

.dott-right {
  border: solid 1px #00000024;
  width: 100%;
  border-bottom: none;
  margin-left: 35px;
  border-right: 0;
  border-left: 0px;
}


.arrow {
  position: absolute;
  bottom: -10px;
  left: -0px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid navy;
}

.roww-history {
  height: 370px;
}

.history-article-img {
  margin-bottom: 100px !important;
  margin-left: 20px !important;
}

.history-article-last {
  height: 20px;
}

.his-last {
  margin-bottom: 80px !important;
}

.aboutus-slider-container {
  display: none;
}

.aboutus-slider-body-one {
  display: block;
}

.aboutus-slider-body-two {
  display: block;
}

.aboutus-header {
  color: var(--text, #0D1D2F);
  font-size: 32px;
  font-family: Mosse;
  font-style: normal;
  font-weight: 700;
  line-height: 116.6%;
  letter-spacing: -0.16px;
}

.aboutus-listview {
  display: none;
}

.body-middle-container {
  background: var(--bg-blue, linear-gradient(222deg, #151015 0%, #060290 100%));
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sidebar-information {
    width: 30px !important;
    height: 1000px !important;
  }

  .content-information {
    margin-left: 30px !important;
    height: 1000px !important;
  }
}

@media screen and (max-width: 767px) {
  .sidebar-information {
    display: none;
  }

  .content-information {
    width: 100%;
    margin-left: 0px !important;
    height: 900px !important;
  }

  .faq-content {
    font-size: 15px !important;
  }

  .information-heading {
    margin-top: 25px !important;
    font-size: 22px !important;
  }

  .information-head {
    font-size: 22px !important;
    margin-top: -4px !important;
  }

  .faq-questions-info {
    font-size: 12px !important;
  }

  .about-history-box-right {
    height: 6200px !important;
    position: relative;
    width: 100%;
    left: 0px;
  }

  .roww-history {
    height: auto;
  }

  .vlr ::before {
    display: none;
  }

  .history-article-img {
    margin-left: 0px !important;
    height: 200px !important;
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 20px !important;
  }

  .about-history-box-right {
    height: 6500px !important;
  }

  .history-mobile {
    flex-direction: column-reverse;
  }

  .history-article-p {
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    font-family: "Mosse",
  }

  .vertical-line {
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2px;
    background-color: #060290;
    margin: auto;
    height: 70px;
    margin-bottom: 5px;
  }

  .dot {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #060290;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.7), 0 0 9px #808080;
  }

  .info-top-title {
    margin-top: 30px;
  }

  .information-navbar {
    height: 120px;
  }

  .aboutus-footer {
    display: none;
  }



  .about-commander-box-left {
    display: none;
  }

  .about-commander-box-right {
    left: 10px;
  }

  .aboutus-header {
    display: none;
  }

  .aboutus-slider {
    display: flex;
    margin: 0 auto;
    max-width: 80%;
  }

  .aboutus-slider-container {
    display: block;
    padding-bottom: 20px;
    margin-bottom: 40px;
    /* border-bottom: 1px solid #00000024; */
  }

  .aboutus-slider-body-one {
    display: none;
  }

  .aboutus-slider-body-two {
    display: none;
  }

  .aboutus-listview {
    display: block;
  }

  .body-middle-container {
    border-left: none;
  }
}

.is-active {
  color: var(--blue-2, #060290) !important;
  font-size: 20px;
  font-family: Mosse;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.1px;
  text-decoration: none;
  margin: 0px 10px 0px 10px;
}

.text-nemu {
  color: grey;
  font-size: 20px;
  font-family: Mosse;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.1px;
  text-decoration: none;
  margin: 0px 10px 0px 10px;
}

.box-container {
  margin-left: 20px;
  background: var(--gradiant-w-2, linear-gradient(165deg, #FFF 51.04%, #FCFCFC 100%));
  padding-top: 60px;
}




.pd-l-55 {
  padding-left: 40px;
  object-fit: cover;
}

.history-f {
  background: #FFF;
  box-shadow: 0px 30px 103px 0px rgba(0, 0, 0, 0.06);
  margin-left: 45px;
  padding-top: 20px;
  padding-bottom: 50px;
  margin-top: 50px;
}

.card {
  box-shadow: none;
  border-radius: 0px;
}

.title-h {
  color: var(--blue-2, #060290);
  font-family: Mosse;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 116.6%;
  letter-spacing: 0.29px;
  margin-top: -10px;
  margin-left: 40px;
}

@media screen and (min-width:960px) and (max-width: 1280px) {
  .slick-next {
    display: none !important;
  }

  .img {
    width: 120px !important;
    height: 120px !important;
  }

  .card-about {
    height: 315px !important;
  }


}

@media screen and (max-width:959px) {
  .box-container {
    margin-left: 0px !important;
    padding-top: 100px;
  }

  .information-title {
    font-size: 20px;
    margin-left: 10px;
  }

  .is-active {
    margin: 0px 10px 0px 10px;
    font-size: 17px !important;
  }

  .text-nemu {
    font-size: 17px;
  }



  .title-h {
    margin-left: 5px;
    color: var(--blue-2, #060290);
    font-family: Mosse;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.6%;
    letter-spacing: -0.2px;
  }

  .contact-subheadings {
    margin-left: -15px !important;
    margin-top: -50px !important;
  }

  .info-heading {
    margin-left: 10px !important;
  }

  .info-subheading {
    margin-left: 10px !important;
  }

  .faq-questions-info {
    margin-left: 8px !important;
  }
}

.img {
  border-radius: 500px;
  width: 164px;
  height: 164px;
}

.active {
  display: block;
  color: var(--blue-2, #060290) !important;
}