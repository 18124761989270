.faq {
    background: var(--bg-blue, linear-gradient(222deg, #151015 0%, #060290 100%));

}

.row-title {
    height: 90px;
}

.row-title h2 {
    color: navy;
    font-weight: 900;
    font-size: 35px;
    padding-top: 60px !important;
    margin-left: 20px !important;
}

.row-title-img {
    margin-top: 40px;
    right: 10;
    position: absolute;
    height: 45px;
    width: 80px;

}

.map {
    position: relative;
    top: 25%
}

.map button {
    margin: 10px;
}

.map-title {
    background-color: #f1f1f1;
    color: navy;

}

.row-pages {
    height: 70px;
}

.row-pages a {
    color: grey;
    font-weight: 500;
    margin: 5px 20px;
    text-decoration: none;
}


.rowIcon {
    height: 400px;



    align-items: center;
    justify-self: center;

}

.rowwIcon {
    background-image: url(../../assets/images/logo-i.png);
    /*-webkit-background-size :cover ;
    -moz-background-size:cover;
    -moz-background-size: cover;*/
    height: 200px;
    width: 700px !important;
    margin: auto !important;
    /*margin-top:40px !important;
   margin-bottom: 40px !important;*/
    /*margin:50px 400px ;
   margin-top:100px !important;

    background-position: bottom right !important;
    background-repeat: no-repeat;*/



}

.rowwwIcon {
    text-align: center;
    margin: 60px;
    align-items: center;

}

.rowwwIcon i {
    margin: 0 10px;
    margin-top: 10px;
}

.rowwIcon span {

    color: white;


}

.faq-questions {
    margin: 35px 40px !important;
}

.faq-answers {
    margin: 0px 55px !important;
}

@media screen and (max-width: 767px) {
    .sidebar-faq {
        display: none;
    }

    .content-faq {
        width: 100%;
        margin-left: 0px !important;
        height: 650px !important;
    }

    .faq-content {
        font-size: 15px !important;
    }

}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .sidebar-faq {
        width: 30px !important;
        height: 800px !important;
    }

    .content-faq {
        margin-left: 30px !important;
        height: 800px !important;
    }
}

.contact-subhead {
    color: #000;
    font-size: 20px;
    font-family: Mosse;
    line-height: 116.6%;
    letter-spacing: -0.1px;
    opacity: 0.5;

}

.active-class {
    color: var(--blue-2, #060290);
    font-size: 20px;
    font-family: Mosse;
    line-height: 116.6%;
    letter-spacing: -0.1px;
}