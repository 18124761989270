.page-container {
  overflow: hidden;
  /* margin-top: 75px; */
}

.rowCarousel {
  display: flex;
  height: 700px;
  background-image: url("../../assets/images/b1.png");
  background-size: 100% 100%;
}

.colSlogan {
  background: radial-gradient(98.61% 220.32% at 6.34% 6.17%,
      rgba(155, 155, 155, 0.41) 0%,
      rgba(152, 152, 152, 0.38) 100%);
  box-shadow: -18px 0px 112px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(100px);
  height: auto;
}

.col-slogan2 {
  height: auto;
}

.card-text {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.005em;
  color: #0d1d2f;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-title {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: navy;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card-date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #00000052;
}

.colCarousel {
  height: 700px;
}

/* .carousel-caption {
  position: static;
} */
/* @font-face {
  font-family: "Mosse";
  src: url("../../../public/fonts/mosse-thai-bold.otf") format("opentype");
} */

.styleSlogan {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 116.6%;
  /* or 68px */
  letter-spacing: -0.005em;
  text-align: left;
  color: #ffffff;
}

.stylePara {
  font-family: "Mosse";
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  line-height: 116.6%;
  /* or 23px */
  margin-top: 30px;
  text-align: left;

  letter-spacing: -0.005em;

  color: #ffffff;

  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
}

.styleImg {
  width: 128px;
  height: 71.2px;
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: block;
}

.styleBg {

  position: absolute;
  bottom: 0;
  right: 0;
  display: block;

}

.styleScroll {
  height: 70px;
  align-items: center;
  background: radial-gradient(1782.05% 4323.49% at 44.77% -932.69%,
      #747474 0%,
      #000000 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
}

.carousel .carousel-indicators button {
  visibility: hidden;
}

.carousel-caption {
  transform: translateY(-50%);
  top: 50%;
  bottom: initial;
}

.rowCardCarousel {
  margin-left: 40px !important;
  margin-right: 40px !important;
  height: auto;
}

.styleRightCardCarousal {
  background-color: #ffffff !important;
  display: grid;
  position: relative;
  width: calc(100% - 42px);
  left: 42px;

}

.slider-wrapper {
  display: block;
  /* This forces the slider wrapper to take full width */
}

.styleCardCarousal {
  display: flex !important;
}

.styleLeft {
  background: var(--bg-blue, linear-gradient(222deg, #151015 0%, #060290 100%));
  width: 42px !important;
  margin-bottom: -42px;
  /* height: 100%;
  position: absolute;
  left: 0; */
  z-index: -1;
}

.slick-slider .slick-initialized {
  width: 100vw;
}

/* .slick-prev:before {
  display: none !important;
}

.slick-next:before {
  display: none !important;
} */

.card-img-top {
  width: 100% !important;
  height: 35vh !important;
  object-fit: cover !important;
  transition: transform 0.3s;
}

/* .card-img-top:hover {
  transform: scale(1.1);
  filter: brightness(110%);
} */

.card {
  height: fit-content;
  width: 500px;
  max-width: 1000px;
  /* optional maximum width */

  position: relative;
  overflow: hidden;
  box-shadow: -8px 1px 37px rgba(0, 0, 0, 0.01);
  border-radius: 0px 0px 12px 12px;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 0px 0px 12px 12px;

}

.card:hover .card-img-top {

  filter: brightness(110%);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);

}

/*.card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,0.08), rgba(0,0,0,0,05);
    position: "relative";
}*/
.slick-slide>div {
  margin: 20px;
}

.slider-wrapper .slick-slide {
  display: inline-block;
}

.list-wrapper {
  display: none;
  /* Hide the list wrapper by default */
}

.news-para {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slick-list {
  margin: -10px;
}

.style-quiz {
  height: 700px;
}

.titleCard {
  color: navy;
  margin-left: 20px;
  padding-top: 50px;
  font-weight: 700;
  font-size: 52px;
  padding-bottom: 40px;
  font-family: "Mosse";
}

.row-quiz-home {
  text-align: center;
  background-image: url("../../assets/images/rec.png");
  /* height:400px !important;*/
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
}



.quiz-title-home {
  font-family: Mosse;
  color: navy;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-top: 210px !important;
}

.quizz-home {
  text-align: center;
  background-image: url("../../assets/images/bh2.png");
  /* height:400px !important;*/
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
}

.quiz-button-home {
  margin: 30px !important;
  background-color: #ff8a00 !important;
  border-radius: 8px;
  color: #ffffff !important;
  font-family: Mosse !important;
  padding: 12px 24px 20px 24px;
  font-size: 25px;
  font-family: Mosse;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.35px;
}

.quiz-bg-home {
  text-align: center;
  background-image: url("../../assets/images/qb.png");
  /* height: 620px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* opacity: 0.8899999856948853; */
}

.quiz-bg-button-home {
  margin: 30px 0px !important;
  background-color: #ff8a00 !important;
  border-radius: 8px;
  color: #ffffff !important;
  font-family: Mosse !important;
  padding: 12px 24px 20px 24px;
  font-size: 25px;
  font-family: Mosse;
  font-weight: 500;
  line-height: 116.6%;
  letter-spacing: -0.35px;
}



.quiz-bg-title-home {
  color: var(--blue-2, #060290);
  font-size: 60px;
  font-family: Mosse;
  font-weight: 700;
  line-height: 116.6%;
  letter-spacing: -0.6px;
  margin-bottom: 41px;
  padding-top: 80px;
  text-align: left;
}

.quiz-bg-subheading {
  color: #2B3035;
  text-align: center;
  text-shadow: 0px 0px 33px 0px #FFF;
  font-size: 26px;
  font-family: Mosse;
  line-height: 130%;
  letter-spacing: -0.4px;
  text-align: left;
}

.mobile-news {
  display: none;
}

.rowQuiz {
  text-align: center;
  background-image: url("../../assets/images/bh2.png");
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;

  box-shadow: 0 10px 20px -8px;
}

.titleMap {
  color: #0d1d2f;
  font-family: Mosse;
  font-size: 25px;
  line-height: 116.6%;
  text-align: left !important;
  margin-bottom: 20px !important;
  margin-left: 40px;
  font-weight: 500;
  /* or 44px */
  letter-spacing: 0.005em;
}

.quiz {
  position: relative;
  top: 25%;
  text-align: center;
}

.quiz button {
  margin: 10px;
}

.subheadingMap {
  color: grey;
  font-size: 15 px;
  font-family: Mosse;
  text-align: left !important;
  margin-left: 40px;
  /*font-stretch:ultra-expanded;*/
}

.buttonMap {
  background: #060290 !important;
  border-radius: 40px !important;
  margin-top: 30px !important;
  margin-left: 40px !important;
  color: #ffffff !important;
  font-family: Mosse;
}

.fontt {
  font-family: Mosse;
}

.rowMap {
  display: flex !important;
}

.rowMapLeft {
  background: linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%);
  width: 42px !important;
  height: 500px;
  position: absolute;

  left: 0;
}

.rowMapRight {
  background-color: #ffffff !important;
  height: 500px !important;
  position: relative;
  width: calc(100% - 42px);
  left: 42px;
}

.map {
  position: relative;
  top: 25%;
}

.iconMap {
  height: 35px;
  margin-left: 5px;
}

.map button {
  margin: 10px;
}

.rowIcon {
  height: 500px;
  background: linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%);
  align-items: center;
  justify-self: center;
}

.rowwIcon {
  background-image: url("../../assets/images/logo-i.png");
  background-size: 100% 100%;
  height: 200px;
  width: 700px !important;
  /*margin:50px 400px ;
   margin-top:100px !important;*/
  align-items: center !important;
  text-align: center;
  position: center;
  margin: auto !important;
}

.roww-new-idmb {
  background-image: url("../../assets/images/logo-i.png");
}

.bg-q {
  background-image: url("../../assets/images/Union.png");
  background-size: 100% 100%;
  height: 200px;

}

.rowwwIcon {
  text-align: center;
  margin: 70px;
  align-items: center;
}

.rowwwIcon i {
  margin: 0 10px;
  margin-top: 10px;
}

.rowwIcon span {
  color: white;
}

.iconImg {
  width: 46px;
  color: #ffffff;
  margin: 10px;
}

@media screen and (max-width: 600px) {
  .rowCarousel {
    flex-direction: column-reverse;
    height: 700px;
  }

  .colSlogan {
    height: 300px;
    width: 100%;
  }

  .col-slogan2 {
    height: 500px;
    width: 100%;
  }

  .colCarousel {
    height: 300px;
  }

  .styleSlogan {
    font-size: 24px;
  }

  .styleScroll {
    height: 60px;
  }

  .stylePara {
    font-size: 15px;
    margin-top: 15px;
  }

  .styleImg {
    width: 108px;
    height: 58.2px;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
}



@media screen and (max-width: 767px) {
  .quiz-bg-home {
    background-size: cover;
  }

  .quiz-bg-title-home {
    font-size: 26px;
  }

  .quiz-bg-title-mobile {
    display: block;
  }

  .quiz-bg-button-home {
    margin-top: 18px !important;
  }

  .content-home {
    width: 100%;
    margin-left: 0px !important;
    height: 780px !important;
  }

  /* For screens less than 768px wide, hide the slider and show the list */
  .slider-wrapper {
    display: none;
  }

  .list-wrapper {
    display: block;
    /* Show the list */
    overflow-x: scroll;
    /* Add horizontal scrolling */
    white-space: nowrap;
    /* This prevents the cards from wrapping to a new line */
  }

  .list-wrapper .card {
    height: calc(100% / 5);
    /* Set the width of each card to 20% */
    flex-shrink: 0;
    /* Prevent the cards from shrinking */
  }

  .mobile-news {
    display: block;
    margin-top: 120px;
  }

  .styleLeft {
    display: none;
  }

  .styleRightCardCarousal {
    margin: 0px 10px 0px 10px;
    left: 0px;
  }

  .titleCard {
    font-family: "Mosse";
    color: #0d1d2f;
    font-size: 24px;
    margin-left: 20px;
    padding-top: 30px;
    font-weight: 700;
    letter-spacing: -0.01em;
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .quiz-bg-home {
    height: 400px;
    margin-top: 50px;
  }

  .quiz-bg-title-home {
    padding-top: 20px;
    font-size: 28px;
    text-align: center;
  }



  .quiz-bg-button-home {
    margin-top: 15px !important;
  }

  .content-home {
    /* margin-left: 30px !important; */
    height: 450px !important;
  }

  /* .tablet {
      width: 100%;
      margin-top: 0px;
    }
  
    .tablet-hied {
      display: none;
    }
  
    .quiz-bg-title-home {
      text-align: center;
    }
  
    .quiz-bg-subheading {
      text-align: center;
    }
  
    .flex {
      display: block !important;
    } */
}

.tablet {
  margin-top: 75px;
}

.tablet-hied {
  display: block !important;
}

.flex {
  display: flex;
}

@media screen and (max-width:959px) {
  .styleBg {
    display: none;
  }

  .styleImg {
    display: none;
  }

  .quiz-bg-subheading {
    color: rgba(43, 48, 53, 0.50);
    text-align: center;
    text-shadow: 0px 0px 33px 0px #FFF;
    font-size: 16px;
    font-family: Mosse;
    line-height: 130%;
    letter-spacing: -0.16px;
    margin-bottom: 15px;
  }

  .quiz-bg-home {
    padding-top: 50%;
  }

  .quiz-bg-title-home {
    padding-top: 0px;
    text-align: center !important;
  }

  .quiz-bg-title-mobile {
    display: none;
  }

  .contact-map-title {
    text-align: center !important;
  }

  .contact-map-subtitle {
    text-align: center !important;
  }

  .slick-prev,
  .slick-next {
    top: 38px !important;
  }

  .box-see-more {
    padding: 0 !important;
  }
}

/* .slick-arrow {
  display: none !important;
} */

.text-carousel {
  color: #FFF;
  font-size: 45px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 116.6%;
  letter-spacing: -0.27px;
  text-align: left;
}

.text-carousel1 {
  color: #FFF;
  text-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.16);
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 116.6%;
  letter-spacing: -0.1px;
  margin-top: 40px;
  text-align: left;
}

@media screen and (min-width:960px) and (max-width: 1280px) {
  .text-carousel {
    font-size: 35px !important;
  }

  .text-carousel1 {
    font-size: 16px !important;
  }

  .styleLeft {
    width: 16px !important;
  }

  .quiz-bg-home {
    width: 100%;
    margin-left: 16px;

  }

  .box-map {
    position: relative;
    left: 0px !important;
  }

  .col-mode-mobile {
    display: none;
  }

  .quiz-bg-title-home {
    padding-top: 20px;
    font-size: 28px;
    text-align: center;
  }

  .quiz-bg-title-mobile {
    display: none;
  }

  .quiz-bg-subheading {
    text-align: center;
  }

  .quiz-bg-button-home {
    height: 49px;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.6%;
    letter-spacing: -0.17px;

  }

  .width-100 {
    width: 100%;
  }

  .text-left {
    text-align: center !important;
  }

  .card {
    height: auto !important;
  }

  .box-content {
    position: relative !important;
    padding-left: 0px !important;
    bottom: 0px !important;
  }

  .styleRightCardCarousal {
    padding-left: 25px;
    width: calc(100% - 0px) !important;
    left: 16px !important;
  }

  .titleCard {
    margin-left: 16px !important;
  }

  .content-home {
    margin-left: 28px !important;
  }

  .quiz-tablet {
    margin-top: 0px !important;
    margin-left: -55px;
    position: unset !important;
    margin-top: 0px !important;
    width: auto !important;
  }

  .quiz-bg-subheading {
    font-size: 17px !important;
  }
}


.see-more {
  color: #414141;
  font-size: 13px;
  font-family: Mosse;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.13px;
  display: block;
  margin-right: 16px;

}

.style-box-map {
  background-color: #ffffff !important;

  position: relative;
  width: calc(100% - 42px);
  left: 42px;
}

@media screen and (max-width:959px) {
  .text-carousel {
    font-size: 20px !important;
  }

  .text-carousel1 {
    font-size: 12px !important;
  }

  .box-map {
    position: relative;
    left: 0px !important
  }



  .styleRightCardCarousal {
    background-color: #ffffff !important;
    height: auto !important;
    position: relative;
    width: calc(100% - 0px);
    left: 0px;
    margin: 0px 16px 0px 0px;
  }

  .container-fluid {
    --bs-gutter-x: 0rem !important;
  }

  .quiz-bg-title-mobile {
    display: none;
  }

  .box-news {
    display: block !important;
    overflow: hidden !important;


  }

  .card {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .box-content {
    position: static !important;
    padding-left: 0px !important;
    padding-top: 0px !important;

  }

  .quiz-tablet {
    display: none;
  }

  .contact-box-map {
    box-shadow: none !important;
  }

  .bg-home {
    background: #FFF !important;
  }

  .contact-map-col-12 {
    margin-left: 1px;
    padding-right: 0px !important;
  }

  .quiz-mobile {
    display: block !important;
  }

  .quiz-bg-subheading {
    font-size: 16px !important;
  }

  .quiz-bg-button-home {
    font-size: 20px;
  }
}


.quiz-mobile .quiz-bg-title-home {
  color: var(--blue-2, #060290);
  text-align: center;
  font-family: Mosse;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 116.6%;
  margin-bottom: 20px;
  letter-spacing: -0.32px;
  margin-top: 123px;
}

.bg-home {
  background: linear-gradient(221.95deg, #151015 16.97%, #060290 83.68%)
}

.card {
  width: 380px;
  margin-left: 20px !important;
  /* height: 100% !important; */
  cursor: pointer;
  margin-bottom: 10px;

}

.tage {
  padding: 10px 12px 13px 13px;
  background: rgba(0, 0, 0, 0.80);
  color: #FFF;
  font-size: 20px;
  font-family: Mosse;
  font-style: normal;
  font-weight: 400;
  line-height: 116.6%;
  margin-left: 10px;
  letter-spacing: -0.2px;
  /* position: absolute;
  bottom: 165px; */
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.80);

}

.box-news {
  display: flex;
  overflow: auto;

}

.h-96 {
  height: 96% !important;
}

.card-news {
  margin-left: 20px !important;
}

.quiz-tablet {
  margin-top: 84px;
  position: relative;
  left: -84px;
  width: max-content;
}

.quiz-mobile {
  display: none;
}

.text-marquee {
  font-family: Mosse;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #FFF;
}