/* First Loader */
.loader {
    position: relative;
    height:100vh;
    width:100vw;
    align-items: center;
    justify-content: center;
    
    
    
  }

 
  .left-image {
    position: absolute;
    height: 80px !important;
    top:230px;
    left:-100%;
    animation: slide-in-left 1.5s forwards;
    
    
    
  }
  
  .right-image {
    position: absolute;
    height: 25px !important;
    top:255px;
    right:-100%;
    animation: slide-in-right 1.5s forwards;

    
  }
  
  @keyframes slide-in-left {
    100% {
      left: 520px;
    }
  }
  
  @keyframes slide-in-right {
    100% {
      right: 535px;
    }
  }
  
  
  
  
  
  
  