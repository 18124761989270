@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



@font-face {
  font-family: "MosseBold";
  src: url("../public/fonts/mosse-thai-bold.otf") format("opentype");
}

@font-face {
  font-family: "Mosse";
  src: url("../public/fonts/mosse-thai-book.otf") format("opentype");
}

body {
  overflow-x: hidden;
  overflow-y: auto;

}